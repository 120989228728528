// extracted by mini-css-extract-plugin
export var navBar = "navigation-module--navBar--2tkJX";
export var link = "navigation-module--link--3AYtq";
export var item = "navigation-module--item--2c7Cn";
export var main = "navigation-module--main--39Fhk";
export var pole = "navigation-module--pole--RjRd7";
export var text = "navigation-module--text--3foaU";
export var top = "navigation-module--top--SqLBR";
export var nav = "navigation-module--nav--VJ0f8";
export var textHover = "navigation-module--text-hover--b9oR4";
export var accordion = "navigation-module--accordion--q4Xd4";
export var headPole = "navigation-module--headPole--2RBXk";
export var poleExtend = "navigation-module--poleExtend--10Rl4";