import * as React from 'react'
import Layout from "../components/organisms/layout/layout";

const ErrorPage = () => {
    return (
        <Layout>
		This is the 404 page, something broke so ill just go and fix that.
        </Layout>
    )
}

export default ErrorPage
